import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const stageDefColorList =  [
    { key: 0, name: i18n.t('STAGEDEFCOLORLIST.0'), hexCode: '#f05975' },
    { key: 1, name: i18n.t('STAGEDEFCOLORLIST.1'), hexCode: '#de7504' },
    { key: 2, name: i18n.t('STAGEDEFCOLORLIST.2'), hexCode: '#e4c41c' },
    { key: 3, name: i18n.t('STAGEDEFCOLORLIST.3'), hexCode: '#00aa60' },
    { key: 4, name: i18n.t('STAGEDEFCOLORLIST.4'), hexCode: '#518bf2' },
    { key: 5, name: i18n.t('STAGEDEFCOLORLIST.5'), hexCode: '#7b68ee' },
];

export default stageDefColorList;