import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"
}
i18n.locale = lang

const activityPriorityList =  [
    { key: "URGENT", name: i18n.t('ACTIVITY.URGENT') },
    { key: "IMPORTANT", name: i18n.t('ACTIVITY.IMPORTANT') },
    { key: "MEDIUM", name: i18n.t('ACTIVITY.MEDIUM') },
    { key: "LOW", name: i18n.t('ACTIVITY.LOW') },
];

export default activityPriorityList;