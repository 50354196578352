import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const industryList = [
    { key: 0, name: i18n.t('INDUSTRYLIST.0') },
    { key: 1, name: i18n.t('INDUSTRYLIST.1') },
    { key: 2, name: i18n.t('INDUSTRYLIST.2') },
    { key: 3, name: i18n.t('INDUSTRYLIST.3') },
    { key: 4, name: i18n.t('INDUSTRYLIST.4') },
    { key: 5, name: i18n.t('INDUSTRYLIST.5') },
    { key: 6, name: i18n.t('INDUSTRYLIST.6') },
    { key: 7, name: i18n.t('INDUSTRYLIST.7') },
    { key: 8, name: i18n.t('INDUSTRYLIST.8') },
    { key: 9, name: i18n.t('INDUSTRYLIST.9') },
    { key: 10, name: i18n.t('INDUSTRYLIST.10') },
    { key: 11, name: i18n.t('INDUSTRYLIST.11') },
    { key: 12, name: i18n.t('INDUSTRYLIST.12') },
    { key: 13, name: i18n.t('INDUSTRYLIST.13') },
    { key: 14, name: i18n.t('INDUSTRYLIST.14') },
    { key: 15, name: i18n.t('INDUSTRYLIST.15') },
    { key: 16, name: i18n.t('INDUSTRYLIST.16') },
    { key: 17, name: i18n.t('INDUSTRYLIST.17') },
    { key: 18, name: i18n.t('INDUSTRYLIST.18') },
];

export default industryList;