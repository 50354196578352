import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const segment_typeList = [
    {name:i18n.t('SEGMENT_TYPELIST.STATIC'),key:"STATIC"},
    {name:i18n.t('SEGMENT_TYPELIST.DYNAMIC'),key:"DYNAMIC"},   
];
export default segment_typeList;