import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const levelList =  [
    { key: 0, name: i18n.t('LEVELLIST.0') },
    // { key: 0, name: 'Staff' },
    { key: 1, name: i18n.t('LEVELLIST.1') },
    { key: 2, name: i18n.t('LEVELLIST.2') },
    { key: 3, name: i18n.t('LEVELLIST.3') },
    { key: 4, name: i18n.t('LEVELLIST.4') },
];

export default levelList
;