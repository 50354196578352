import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"
}
i18n.locale = lang

const activityCompletedList =  [
    { key: "COMPLETED", name: i18n.t('ACTIVITY.COMPLETED_COMPLETED') },
    { key: "UNCOMPLETED", name: i18n.t('ACTIVITY.COMPLETED_UNCOMPLETED') },
];

export default activityCompletedList;