import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"
}
i18n.locale = lang

const casePriorityList =  [
    { key: "5", name: i18n.t('CASEPRIORITYLIST.VERY_HIGH') },
    { key: "4", name: i18n.t('CASEPRIORITYLIST.HIGH') },
    { key: "3", name: i18n.t('CASEPRIORITYLIST.MEDIUM') },
    { key: "2", name: i18n.t('CASEPRIORITYLIST.LOW') },
    { key: "1", name: i18n.t('CASEPRIORITYLIST.VERY_LOW') },
];

export default casePriorityList;