import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const orgtypeList= [
    { key: "外國公司在台之分公司", name: i18n.t('ORGTYPELIST.0') },
    { key: "外國公司在台之辦事處", name: i18n.t('ORGTYPELIST.1') },
    { key: "本國公司設立之分公司", name: i18n.t('ORGTYPELIST.2') },
    { key: "合作社", name: i18n.t('ORGTYPELIST.3') },
    { key: "合夥", name: i18n.t('ORGTYPELIST.4') },
    { key: "有限公司", name: i18n.t('ORGTYPELIST.5') },
    { key: "有限合夥", name: i18n.t('ORGTYPELIST.6') },
    { key: "兩合公司", name: i18n.t('ORGTYPELIST.7') },
    { key: "其他", name: i18n.t('ORGTYPELIST.8') },
    { key: "股份有限公司", name: i18n.t('ORGTYPELIST.9') },
    { key: "無限公司", name: i18n.t('ORGTYPELIST.10') },
    { key: "獨資", name: i18n.t('ORGTYPELIST.11') },    
  ];

export default orgtypeList;