import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang


const GPTModelListForUpsdie =  [
    {name:"GPT 4o",id:"gpt4o"},
    {name:"GPT 4o mini",id:"gpt4o-mini"},
];

export default GPTModelListForUpsdie;