import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang


const AssistantLanguageList =  [
    {key:'auto',name:i18n.t('UPGPT.AUTO_DETECT')},
    {key:'Traditional Chinese',name:'繁體中文'},
    {key:'English',name:'English'},
    {key:'Vietnamese',name:'Tiếng Việt'},
    {key:'Burmese',name:'မြန်မာအက္ခရာ'},
    {key:'Laotian',name:'ພະຍັນຊະນະລາວ'},
    {key:'Turkish',name:'Türkçe'},
    {key:'Thai',name:'ไทย'},
    {key:'Telugu',name:'తెలుగు'},
    {key:'Albanian',name:'Shqip'},
    {key:'Slovenian',name:'Slovenščina'},
    {key:'Slovak',name:'Slovenčina'},
    {key:'Russian',name:'Русский'},
    {key:'Romanian',name:'Română'},
    {key:'Brazilian Portuguese',name:'Português do Brasil'},
    {key:'Portuguese',name:'Português'},
    {key:'Dutch',name:'Nederlands'},
    {key:'Mongolian',name:'Mongolian'},
    {key:'Korean',name:'한국어'},
    {key:'Georgian',name:'ქართული'},
    {key:'Japanese',name:'日本語'},
    {key:'Italian',name:'Italiano'},
    {key:'Indonesian',name:'Bahasa Indonesia'},
    {key:'Hungarian',name:'Magyar'},
    {key:'Hebrew',name:'עברית '},
    {key:'French',name:'Français'},
    {key:'Finnish',name:'suomi'},
    {key:'Spanish',name:'Español'},
    {key:'German',name:'Deutsch'},
    {key:'Danish',name:'Dansk'},
    {key:'Czech',name:'Čeština'},
    {key:'Catalan',name:'Catalan'},
    {key:'Bulgarian',name:'Български'},
    {key:'Arabic',name:'العربية'},
    {key:'Simplified Chinese',name:'简体中文'},
    {key:'Polish',name:'Polski'},
    {key:'Swedish',name:'Svenska'},
];

export default AssistantLanguageList;