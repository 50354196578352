import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const pageList = [
    { key:'Contact', name: i18n.t('CONTACT.TITLE')},
		{ key:'Organization', name: i18n.t('COMPANY.TITLE')},
		{ key:'Case', name: i18n.t('CASE.TITLE')},
		{ key:'Opportunity',name: i18n.t('DEAL.TITLE')},
		{ key:'KnowledgeArticle',name: i18n.t('KNOWLEDGE_ARTICLE.TITLE')},
		{ key:'Activity',name: i18n.t('ACTIVITY.TITLE')},
];

export default pageList;