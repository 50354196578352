import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const op_text_List = [
  { key:'contains', name: i18n.t('OP_TEXT.CONTAINS')},
	{ key:'not_contain', name: i18n.t('OP_TEXT.NOT_CONTAINS')},
	{ key:'is_empty', name: i18n.t('OP_TEXT.IS_EMPTY')},
	{ key:'not_empty',name: i18n.t('OP_TEXT.NOT_EMPTY')},
];

export default op_text_List;