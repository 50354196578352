import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const locationList = [
{name:'臺灣', cn_name:'台湾', en_name:'Taiwan', key:'TW'},
{name:'香港', cn_name:'香港', en_name:'Hong Kong', key:'HK'},
{name:'新加坡', cn_name:'新加坡', en_name:'Singapore', key:'SG'},
{name:'日本', cn_name:'日本', en_name:'Japan', key:'JP'},
{name:'美國', cn_name:'美国', en_name:'U.S', key:'US'},
{name:'中國', cn_name:'中国', en_name:'China', key:'CN'},
{name:'英國', cn_name:'英国', en_name:'U.K', key:'GB'},
{name:'法國', cn_name:'法国', en_name:'France', key:'FR'},
{name:'德國', cn_name:'德国', en_name:'Germany', key:'DE'},
{name:'阿富汗', cn_name:'阿富汗', en_name:'Afghanistan', key:'AF'},
{name:'阿爾巴尼亞', cn_name:'阿尔巴尼亚', en_name:'Albania', key:'AL'},
{name:'阿爾及利亞', cn_name:'阿尔及利亚', en_name:'Algeria', key:'DZ'},
{name:'美屬薩摩亞', cn_name:'美属萨摩亚', en_name:'American Samoa', key:'AS'},
{name:'安道爾', cn_name:'安道尔', en_name:'Andorra', key:'AD'},
{name:'安哥拉', cn_name:'安哥拉', en_name:'Angola', key:'AO'},
{name:'安圭拉', cn_name:'安圭拉', en_name:'Anguilla', key:'AI'},
{name:'南極洲', cn_name:'南极洲', en_name:'Antarctica', key:'AQ'},
{name:'安地卡及巴布達', cn_name:'安提瓜和巴布达', en_name:'Antigua and Barbuda', key:'AG'},
{name:'阿根廷', cn_name:'阿根廷', en_name:'Argentina', key:'AR'},
{name:'亞美尼亞', cn_name:'亚美尼亚', en_name:'Armenia', key:'AM'},
{name:'阿魯巴', cn_name:'阿鲁巴', en_name:'Aruba', key:'AW'},
{name:'澳洲', cn_name:'澳大利亚', en_name:'Australia', key:'AU'},
{name:'奧地利', cn_name:'奥地利', en_name:'Austria', key:'AT'},
{name:'亞塞拜然', cn_name:'阿塞拜疆', en_name:'Azerbaijan', key:'AZ'},
{name:'巴哈馬', cn_name:'巴哈马', en_name:'Bahamas', key:'BS'},
{name:'巴林', cn_name:'巴林', en_name:'Bahrain', key:'BH'},
{name:'孟加拉', cn_name:'孟加拉', en_name:'Bengal', key:'BD'},
{name:'巴貝多', cn_name:'巴巴多斯', en_name:'Barbados', key:'BB'},
{name:'白俄羅斯', cn_name:'白俄罗斯', en_name:'Belarus', key:'BY'},
{name:'比利時', cn_name:'比利时', en_name:'Belgium', key:'BE'},
{name:'貝里斯', cn_name:'伯利兹', en_name:'Belize', key:'BZ'},
{name:'貝南', cn_name:'贝宁', en_name:'Benan', key:'BJ'},
{name:'百慕達', cn_name:'百慕大', en_name:'Bermuda', key:'BM'},
{name:'不丹', cn_name:'不丹', en_name:'Bhutan', key:'BT'},
{name:'玻利維亞', cn_name:'玻利维亚', en_name:'Bolivia', key:'BO'},
{name:'波士尼亞與赫塞哥維納', cn_name:'波斯尼亚和黑塞哥维那', en_name:'Bosnia and Herzegovina', key:'BA'},
{name:'波札那', cn_name:'博茨瓦纳', en_name:'Botswana', key:'BW'},
{name:'布韋島', cn_name:'布维岛', en_name:'Bouvet Island', key:'BV'},
{name:'巴西', cn_name:'巴西', en_name:'Brazil', key:'BR'},
{name:'英屬印度洋領地', cn_name:'英属印度洋领地', en_name:'British Indian Ocean Territory', key:'IO'},
{name:'英屬維京群島', cn_name:'英属维尔京群岛', en_name:'British Virgin Islands', key:'VG'},
{name:'汶萊', cn_name:'文莱', en_name:'Brunei', key:'BN'},
{name:'保加利亞', cn_name:'保加利亚', en_name:'Bulgaria', key:'BG'},
{name:'布吉納法索', cn_name:'布基纳法索', en_name:'Burkina Faso', key:'BF'},
{name:'緬甸', cn_name:'缅甸', en_name:'Myanmar', key:'MM'},
{name:'蒲隆地', cn_name:'布隆迪', en_name:'Burundi', key:'BI'},
{name:'維德角', cn_name:'佛得角', en_name:'Cape Verde', key:'CV'},
{name:'柬埔寨', cn_name:'柬埔寨', en_name:'Cambodia', key:'KH'},
{name:'喀麥隆', cn_name:'喀麦隆', en_name:'Cameroon', key:'CM'},
{name:'加拿大', cn_name:'加拿大', en_name:'Canada', key:'CA'},
{name:'開曼群島', cn_name:'开曼群岛', en_name:'Cayman Islands', key:'KY'},
{name:'中非', cn_name:'中非', en_name:'Central Africa', key:'CF'},
{name:'查德', cn_name:'乍得', en_name:'Chad', key:'TD'},
{name:'智利', cn_name:'智利', en_name:'Chile', key:'CL'},
{name:'聖誕島', cn_name:'圣诞岛', en_name:'Christmas island', key:'CX'},
{name:'科科斯（基林）群島', cn_name:'科科斯（基林）群岛', en_name:'Cocos (Keeling) Islands', key:'CC'},
{name:'哥倫比亞', cn_name:'哥伦比亚', en_name:'Colombia', key:'CO'},
{name:'葛摩', cn_name:'科摩罗', en_name:'Comoros', key:'KM'},
{name:'剛果民主共和國', cn_name:'刚果民主共和国', en_name:'Democratic Republic of Congo', key:'CD'},
{name:'剛果共和國', cn_name:'刚果共和国', en_name:'The Republic of Congo', key:'CG'},
{name:'庫克群島', cn_name:'库克群岛', en_name:'Island', key:'CK'},
{name:'哥斯大黎加', cn_name:'哥斯达黎加', en_name:'Costa Rica', key:'CR'},
{name:'象牙海岸', cn_name:'象牙海岸', en_name:'Ivory Coast', key:'CI'},
{name:'克羅埃西亞', cn_name:'克罗地亚', en_name:'Croatia', key:'HR'},
{name:'古巴', cn_name:'古巴', en_name:'Cuba', key:'CU'},
{name:'古拉索', cn_name:'库拉索', en_name:'Gurazzo', key:'CW'},
{name:'賽普勒斯', cn_name:'塞浦路斯', en_name:'Cyprus', key:'CY'},
{name:'捷克', cn_name:'捷克', en_name:'Czech', key:'CZ'},
{name:'丹麥', cn_name:'丹麦', en_name:'Denmark', key:'DK'},
{name:'吉布地', cn_name:'吉布提', en_name:'Djibouti', key:'DJ'},
{name:'多米尼克', cn_name:'多米尼克', en_name:'Dominica', key:'DM'},
{name:'多明尼加', cn_name:'多米尼加共和国', en_name:'Dominican Republic', key:'DO'},
{name:'厄瓜多', cn_name:'厄瓜多尔', en_name:'Ecuador', key:'EC'},
{name:'埃及', cn_name:'埃及', en_name:'Egypt', key:'EG'},
{name:'薩爾瓦多', cn_name:'萨尔瓦多', en_name:'salvador', key:'SV'},
{name:'赤道幾內亞', cn_name:'赤道几内亚', en_name:'Equatorial Guinea', key:'GQ'},
{name:'厄利垂亞', cn_name: '厄立特里亚', en_name:'Eritrea', key:'ER'},
{name:'愛沙尼亞', cn_name: '爱沙尼亚', en_name:'Estonia', key:'EE'},
{name:'衣索比亞', cn_name: '埃塞俄比亚', en_name:'Ethiopia', key:'ET'},
{name:'福克蘭群島', cn_name: '福克兰群岛', en_name:'Falkland Islands', key:'FK'},
{name:'法羅群島', cn_name: '法罗群岛', en_name:'Faroe Islands', key:'FO'},
{name:'斐濟', cn_name: '斐济', en_name:'Fiji', key:'FJ'},
{name:'芬蘭', cn_name: '芬兰', en_name:'Finland', key:'FI'},
{name:'法國本土', cn_name: '法国本土', en_name:'Native French', key:'FX'},
{name:'法屬圭亞那', cn_name: '法属圭亚那', en_name:'French Guiana', key:'GF'},
{name:'法屬玻里尼西亞', cn_name: '法属波利尼西亚', en_name:'French Polynesia', key:'PF'},
{name:'法屬南部和南極領地', cn_name: '法属南部和南极领地', en_name:'French Southern and Antarctic Territories', key:'TF'},
{name:'加彭', cn_name: '加蓬', en_name:'Gabon', key:'GA'},
{name:'甘比亞', cn_name: '冈比亚', en_name:'Gambia', key:'GM'},
{name:'巴勒斯坦', cn_name: '巴勒斯坦', en_name:'Palestine', key:'PS'},
{name:'喬治亞', cn_name: '乔治亚', en_name:'Georgia', key:'GE'},
{name:'迦納', cn_name: '加纳', en_name:'Ghana', key:'GH'},
{name:'直布羅陀', cn_name: '直布罗陀', en_name:'Gibraltar', key:'GI'},
{name:'希臘', cn_name: '希腊', en_name:'Greece', key:'GR'},
{name:'格陵蘭', cn_name: '格陵兰', en_name:'Greenland', key:'GL'},
{name:'格瑞那達', cn_name: '格林纳达', en_name:'Grenada', key:'GD'},
{name:'瓜地洛普', cn_name: '瓜德罗普', en_name:'Guadeloupe', key:'GP'},
{name:'關島', cn_name: '关岛', en_name:'Guam', key:'GU'},
{name:'瓜地馬拉', cn_name: '危地马拉', en_name:'Guatemala', key:'GT'},
{name:'根西', cn_name: '根西', en_name:'Guernsey', key:'GG'},
{name:'幾內亞', cn_name: '几内亚', en_name:'Guinea', key:'GN'},
{name:'幾內亞比索', cn_name: '几内亚比绍', en_name:'Guinea Bissau', key:'GW'},
{name:'蓋亞那', cn_name: '圭亚那', en_name:'Guyana', key:'GY'},
{name:'海地', cn_name: '海地', en_name:'Haiti', key:'HT'},
{name:'赫德島和麥克唐納群島', cn_name: '赫德岛和麦克唐纳群岛', en_name:'Heard and Macdonald Islands', key:'HM'},
{name:'梵蒂岡', cn_name: '梵蒂冈', en_name:'Vatican City', key:'VA'},
{name:'宏都拉斯', cn_name: '洪都拉斯', en_name:'Honduras', key:'HN'},
{name:'匈牙利', cn_name: '匈牙利', en_name:'Hungary', key:'HU'},
{name:'冰島', cn_name: '冰岛', en_name:'Iceland', key:'IS'},
{name:'印度', cn_name: '印度', en_name:'India', key:'IN'},
{name:'印尼', cn_name: '印度尼西亚', en_name:'Indonesia', key:'ID'},
{name:'伊朗', cn_name: '伊朗', en_name:'Iran', key:'IR'},
{name:'伊拉克', cn_name: '伊拉克', en_name:'Iraq', key:'IQ'},
{name:'愛爾蘭', cn_name: '爱尔兰', en_name:'Ireland', key:'IE'},
{name:'曼島', cn_name: '曼岛', en_name:'Isle of Man', key:'IM'},
{name:'以色列', cn_name: '以色列', en_name:'Israel', key:'IL'},
{name:'義大利', cn_name: '意大利', en_name:'Italy', key:'IT'},
{name:'牙買加', cn_name: '牙买加', en_name:'Jamaica', key:'JM'},
{name:'澤西', cn_name: '泽西', en_name:'Jersey', key:'JE'},
{name:'約旦', cn_name: '约旦', en_name:'Jordan', key:'JO'},
{name:'哈薩克', cn_name: '哈萨克斯坦', en_name:'Kazakh', key:'KZ'},
{name:'肯亞', cn_name: '肯尼亚', en_name:'Kenya', key:'KE'},
{name:'吉里巴斯', cn_name: '基里巴斯', en_name:'Kiribati', key:'KI'},
{name:'北韓', cn_name: '朝鲜', en_name:'North Korea', key:'KP'},
{name:'南韓', cn_name: '韩国', en_name:'South Korea', key:'KR'},
{name:'科索沃', cn_name: '科索沃', en_name:'Kosovo', key:'XK'},
{name:'科威特', cn_name: '科威特', en_name:'Kuwait', key:'KW'},
{name:'吉爾吉斯', cn_name: '吉尔吉斯斯坦', en_name:'Kyrgyz', key:'KG'},
{name:'寮國', cn_name: '老挝', en_name:'Laos', key:'LA'},
{name:'拉脫維亞', cn_name: '拉脱维亚', en_name:'Latvia', key:'LV'},
{name:'黎巴嫩', cn_name: '黎巴嫩', en_name:'Lebanon', key:'LB'},
{name:'賴索托', cn_name: '莱索托', en_name:'Lesotho', key:'LS'},
{name:'賴比瑞亞', cn_name: '利比里亚', en_name:'Liberia', key:'LR'},
{name:'利比亞', cn_name: '利比亚', en_name:'Libya', key:'LY'},
{name:'列支敦斯登', cn_name: '列支敦士登', en_name:'Liechtenstein', key:'LI'},
{name:'立陶宛', cn_name: '立陶宛', en_name:'Lithuania', key:'LT'},
{name:'盧森堡', cn_name: '卢森堡', en_name:'Luxembourg', key:'LU'},
{name:'澳門', cn_name: '澳门', en_name:'Macao', key:'MO'},
{name:'北馬其頓', cn_name: '北马其顿', en_name:'North Macedonia', key:'MK'},
{name:'馬達加斯加', cn_name: '马达加斯加', en_name:'Madagascar', key:'MG'},
{name:'馬拉威', cn_name: '马拉维', en_name:'Malawi', key:'MW'},
{name:'馬來西亞', cn_name: '马来西亚', en_name:'Malaysia', key:'MY'},
{name:'馬爾地夫', cn_name: '马尔代夫', en_name:'Maldives', key:'MV'},
{name:'馬利', cn_name: '马里', en_name:'Marley', key:'ML'},
{name:'馬爾他', cn_name: '马耳他', en_name:'Malta', key:'MT'},
{name:'馬紹爾群島', cn_name: '马绍尔群岛', en_name:'Marshall Islands', key:'MH'},
{name:'馬丁尼克', cn_name: '马提尼克', en_name:'Martinique', key:'MQ'},
{name:'茅利塔尼亞', cn_name: '毛里塔尼亚', en_name:'Mauritania', key:'MR'},
{name:'模里西斯', cn_name: '毛里求斯', en_name:'Mauritius', key:'MU'},
{name:'馬約特', cn_name: '马约特', en_name:'Mayotte', key:'YT'},
{name:'墨西哥', cn_name: '墨西哥', en_name:'Mexico', key:'MX'},
{name:'密克羅尼西亞聯邦', cn_name: '密克罗尼西亚联邦', en_name:'Federated States of Micronesia',	key:'FM'},
{name: '摩爾多瓦', cn_name: '摩尔多瓦', en_name: 'Moldova', key: 'MD'},
{name: '摩納哥', cn_name: '摩纳哥', en_name: 'Monaco', key: 'MC'},
{name: '蒙古', cn_name: '蒙古', en_name: 'Mongolia', key: 'MN'},
{name: '蒙特內哥羅', cn_name: '黑山', en_name: 'Montenegro', key: 'ME'},
{name: '蒙哲臘', cn_name: '蒙哲拉特', en_name: 'Montserrat', key: 'MS'},
{name: '摩洛哥', cn_name: '摩洛哥', en_name: 'Morocco', key: 'MA'},
{name: '莫三比克', cn_name: '莫桑比克', en_name: 'Mozambique', key: 'MZ'},
{name: '納米比亞', cn_name: '纳米比亚', en_name: 'Namibia', key: 'NA'},
{name: '諾魯', cn_name: '瑙鲁', en_name: 'Nauru', key: 'NR'},
{name: '尼泊爾', cn_name: '尼泊尔', en_name: 'Nepal', key: 'NP'},
{name: '荷蘭', cn_name: '荷兰', en_name: 'Netherlands', key: 'NL'},
{name: '荷屬安地列斯', cn_name: '荷属安的列斯', en_name: 'Netherlands Antilles', key: 'AN'},
{name: '新喀里多尼亞', cn_name: '新喀里多尼亚', en_name: 'New Caledonia', key: 'NC'},
{name: '紐西蘭', cn_name: '新西兰', en_name: 'New Zealand', key: 'NZ'},
{name: '尼加拉瓜', cn_name: '尼加拉瓜', en_name: 'Nicaragua', key: 'NI'},
{name: '尼日', cn_name: '尼日尔', en_name: 'Niger', key: 'NE'},
{name: '奈及利亞', cn_name: '尼日利亚', en_name: 'Nigeria', key: 'NG'},
{name: '紐埃', cn_name: '纽埃', en_name: 'Niue', key: 'NU'},
{name: '諾福克島', cn_name: '诺福克岛', en_name: 'Norfolk Island', key: 'NF'},
{name: '北馬利安納群島', cn_name: '北马里亚纳群岛', en_name: 'Northern Mariana Islands', key: 'MP'},
{name: '挪威', cn_name: '挪威', en_name: 'Norway', key: 'NO'},
{name: '阿曼', cn_name: '阿曼', en_name: 'Oman', key: 'OM'},
{name: '巴基斯坦', cn_name: '巴基斯坦', en_name: 'Pakistan', key: 'PK'},
{name: '帛琉', cn_name: '帕劳', en_name: 'Palau', key: 'PW'},
{name: '巴拿馬', cn_name: '巴拿马', en_name: 'Panama', key: 'PA'},
{name: '巴布亞紐幾內亞', cn_name: '巴布亚新几内亚', en_name: 'Papua New Guinea', key: 'PG'},
{name: '巴拉圭', cn_name: '巴拉圭', en_name: 'Paraguay', key: 'PY'},
{name: '秘魯', cn_name: '秘鲁', en_name: 'Peru', key: 'PE'},
{name: '菲律賓', cn_name: '菲律宾', en_name: 'The Philippines', key: 'PH'},
{name: '皮特肯群島', cn_name: '皮特凯恩群岛', en_name: 'Pitcairn Islands', key: 'PN'},
{name: '波蘭', cn_name: '波兰', en_name: 'Poland', key: 'PL'},
{name: '葡萄牙', cn_name: '葡萄牙', en_name: 'Portugal', key: 'PT'},
{name: '波多黎各', cn_name: '波多黎各', en_name: 'Puerto Rico', key: 'PR'},
{name: '卡達', cn_name: '卡塔尔', en_name: 'Qatar', key: 'QA'},
{name: '留尼旺', cn_name: '留尼汪', en_name: 'Reunion', key: 'RE'},
{name: '羅馬尼亞', cn_name: '罗马尼亚', en_name: 'Romania', key: 'RO'},
{name: '俄羅斯', cn_name: '俄罗斯', en_name: 'Russia', key: 'RU'},
{name: '盧安達', cn_name: '卢旺达', en_name: 'Rwanda', key: 'RW'},
{name: '聖巴瑟米', cn_name: '圣巴泰勒米', en_name: 'Saint Barthélemy', key: 'BL'},
{name: '聖海蓮娜、阿森松和特里斯坦-達庫尼亞', cn_name: '圣赫勒拿、阿森松和特里斯坦-达库尼亚', en_name: 'Saint Helena, Ascension and Tristan da Cunha', key: 'SH'},
{name: '聖克里斯多福及尼維斯', cn_name: '圣基茨和尼维斯', en_name: 'Saint Kitts and Nevis', key: 'KN'},
{name: '聖露西亞', cn_name: '圣卢西亚', en_name: 'Saint Lucia', key: 'LC'},
{name: '法屬聖馬丁', cn_name: '法属圣马丁', en_name: 'Saint Martin (French part)', key: 'MF'},
{name: '聖文森及格瑞那丁', cn_name: '圣文森特和格林纳丁斯', en_name: 'Saint Vincent and the Grenadines', key: 'VC'},
{name: '薩摩亞', cn_name: '萨摩亚', en_name: 'Samoa', key: 'WS'},
{name: '聖馬利諾', cn_name: '圣马力诺', en_name: 'San Marino', key: 'SM'},
{name: '聖多美普林西比', cn_name: '圣多美和普林西比', en_name: 'Sao Tome and Principe', key: 'ST'},
{name: '沙烏地阿拉伯', cn_name: '沙特阿拉伯', en_name: 'Saudi Arabia', key: 'SA'},
{name: '塞內加爾', cn_name: '塞内加尔', en_name: 'Senegal', key: 'SN'},
{name: '塞爾維亞', cn_name: '塞尔维亚', en_name: 'Serbia', key: 'RS'},
{name: '塞席爾', cn_name: '塞舌尔', en_name: 'Seychelles', key: 'SC'},
{name: '獅子山', cn_name: '塞拉利昂', en_name: 'Sierra Leone', key: 'SL'},
{name: '荷屬聖馬丁', cn_name: '荷属圣马丁', en_name: 'Sint Maarten (Dutch part)', key: 'SX'},
{name: '斯洛伐克', cn_name: '斯洛伐克', en_name: 'Slovakia', key: 'SK'},
{name: '斯洛維尼亞', cn_name: '斯洛文尼亚', en_name: 'Slovenia', key: 'SI'},
{name: '索羅門群島', cn_name: '所罗门群岛', en_name: 'Solomon Islands', key: 'SB'},
{name: '索馬利亞', cn_name: '索马里', en_name: 'Somalia', key: 'SO'},
{name: '南非', cn_name: '南非', en_name: 'South Africa', key: 'ZA'},
{name: '南喬治亞和南桑威奇群島', cn_name: '南乔治亚和南桑威奇群岛', en_name: 'South Georgia and the South Sandwich Islands', key: 'GS'},
{name: '南蘇丹', cn_name: '南苏丹', en_name: 'South Sudan', key: 'SS'},
{name: '西班牙', cn_name: '西班牙', en_name: 'Spain', key: 'ES'},
{name: '斯里蘭卡', cn_name: '斯里兰卡', en_name: 'Sri Lanka', key: 'LK'},
{name: '蘇丹', cn_name: '苏丹', en_name: 'Sudan', key: 'SD'},
{name: '瑞士', cn_name: '瑞士', en_name: 'Switzerland', key: 'CH'},
{name: '敘利亞', cn_name: '叙利亚', en_name: 'Syria', key: 'SY'},
{name: '塔吉克', cn_name: '塔吉克斯坦', en_name: 'Tajikistan', key: 'TJ'},
{name: '坦尚尼亞', cn_name: '坦桑尼亚', en_name: 'Tanzania', key: 'TZ'},
{name: '泰國', cn_name: '泰国', en_name: 'Thailand', key: 'TH'},
{name: '東帝汶', cn_name: '东帝汶', en_name: 'Timor-Leste', key: 'TL'},
{name: '多哥', cn_name: '多哥', en_name: 'Togo', key: 'TG'},
{name: '托克勞', cn_name: '托克劳', en_name: 'Tokelau', key: 'TK'},
{name: '東加', cn_name: '汤加', en_name: 'Tonga', key: 'TO'},
{name: '千里達及托巴哥', cn_name: '特立尼达和多巴哥', en_name: 'Trinidad and Tobago', key: 'TT'},
{name:'突尼西亞', cn_name:'突尼斯', en_name:'Tunisia', key:'TN'},
{name:'土耳其', cn_name:'土耳其', en_name:'Turkey', key:'TR'},
{name:'土庫曼', cn_name:'土库曼斯坦', en_name:'Turkmenistan', key:'TM'},
{name:'特克斯與凱科斯群島', cn_name:'特克斯和凯科斯群岛', en_name:'Turks and Caicos Islands', key:'TC'},
{name:'吐瓦魯', cn_name:'图瓦卢', en_name:'Tuvalu', key:'TV'},
{name:'烏干達', cn_name:'乌干达', en_name:'Uganda', key:'UG'},
{name:'烏克蘭', cn_name:'乌克兰', en_name:'Ukraine', key:'UA'},
{name:'阿聯', cn_name:'阿联酋', en_name:'UAE', key:'AE'},
{name:'美國本土外小島嶼', cn_name:'美国本土外小岛屿', en_name:'United States Minor Outlying Islands', key:'UM'},
{name:'烏拉圭', cn_name:'乌拉圭', en_name:'Uruguay', key:'UY'},
{name:'烏茲別克', cn_name:'乌兹别克斯坦', en_name:'Uzbekistan', key:'UZ'},
{name:'萬那杜', cn_name:'瓦努阿图', en_name:'Vanuatu', key:'VU'},
{name:'委內瑞拉', cn_name:'委内瑞拉', en_name:'Venezuela', key:'VE'},
{name:'越南', cn_name:'越南', en_name:'Vietnam', key:'VN'},
{name:'美屬維京群島', cn_name:'美属维京群岛', en_name:'United States Virgin Islands', key:'VI'},
{name:'瓦利斯和富圖那', cn_name:'瓦利斯和富图纳', en_name:'Wallis and Futuna', key:'WF'},
{name:'巴勒斯坦', cn_name:'巴勒斯坦', en_name:'Palestine', key:'PS'},
{name:'西撒哈拉', cn_name:'西撒哈拉', en_name:'Western Sahara', key:'EH'},
{name:'聯合國', cn_name:'联合国', en_name:'United Nations', key:'UN'},
{name:'歐洲聯盟', cn_name:'欧盟', en_name:'European Union', key:'EU'},
{name:'葉門', cn_name:'也门', en_name:'Yemen', key:'YE'},
{name:'薩伊', cn_name:'萨伊', en_name:'Say', key:'ZA'},
{name:'尚比亞', cn_name:'赞比亚', en_name:'Zambia', key:'ZM'},
{name:'辛巴威', cn_name:'津巴布韦', en_name:'Zimbabwe', key:'ZW'},
{name:'羅德西亞', cn_name:'罗德西亚', en_name:'Rhodesia', key:'RO'},
{name:'西印度群島聯邦', cn_name:'西印度群岛联邦', en_name:'Commonwealth of the West Indies', key:'WI'},
{name:'獨立國家國協', cn_name:'独立国家联合体', en_name:'National Association of Independent States', key:'EN'},
{name:'澳大拉西亞', cn_name:'澳大拉西亚', en_name:'Australasia', key:'AZ'},
{name:'南斯拉夫', cn_name:'南斯拉夫', en_name:'Yugoslavia', key:'YG'},
{name:'蘇聯', cn_name:'苏联', en_name:'Soviet Union', key:'UR'},
{name:'達荷美', cn_name:'达荷美', en_name:'Dahomey', key:'DH'},
{name:'上伏塔', cn_name:'上伏塔', en_name:'Upper Volta', key:'VL'},
{name:'波希米亞', cn_name:'波希米亚', en_name:'Bohemia', key:'BO'},
{name:'捷克斯洛伐克', cn_name:'捷克斯洛伐克', en_name:'Czechoslovakia', key:'TC'},
{name:'阿拉伯聯合共和國', cn_name:'阿拉伯联合共和国', en_name:'United Arab Republic', key:'UA'},
{name:'塞爾維亞與蒙特內哥羅', cn_name:'塞尔维亚和黑山', en_name:'Serbia and Montenegro', key:'SG'}

]

export default locationList;