import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang


const campaignStatusList =  [
    { key: "PROPOSED", name: i18n.t('CAMPAIGNSTAUSLIST.PROPOSED') },
    { key: "READY_TO_ACTIVATE", name: i18n.t('CAMPAIGNSTAUSLIST.READY_TO_ACTIVATE') },
    { key: "ACTIVATED", name: i18n.t('CAMPAIGNSTAUSLIST.ACTIVATED') },
    { key: "COMPLETED", name: i18n.t('CAMPAIGNSTAUSLIST.COMPLETED') },
    { key: "CANCELLED", name: i18n.t('CAMPAIGNSTAUSLIST.CANCELLED') },
    { key: "SHELVED", name: i18n.t('CAMPAIGNSTAUSLIST.SHELVED') },
    { key: "NOT_IN_USE", name: i18n.t('CAMPAIGNSTAUSLIST.NOT_IN_USE') },
];

export default campaignStatusList;