import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)
{
    lang = "zh_TW"
}
i18n.locale = lang

const opptyStatusList =  [    
    
    { key: "OPEN", name: i18n.t('OPPTYSTATUSLIST.OPEN') },
    { key: "WON", name: i18n.t('OPPTYSTATUSLIST.WON') },
    { key: "LOSE", name: i18n.t('OPPTYSTATUSLIST.LOSE')}
    
];

export default opptyStatusList;