import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const opptysFieldList = [
    { key:'created_on', name: i18n.t('OPPTYSFIELDLIST.created_on') ,type:'date'},
	{ key:'created_by', name: i18n.t('OPPTYSFIELDLIST.created_by') ,type:'user'},
	{ key:'updated_on', name: i18n.t('OPPTYSFIELDLIST.updated_on') ,type:'date'},
	{ key:'updated_by',name: i18n.t('OPPTYSFIELDLIST.updated_by') ,type:'user'},
	{ key:'owner',name: i18n.t('OPPTYSFIELDLIST.owner') ,type:'user'},
	{ key:'name',name: i18n.t('OPPTYSFIELDLIST.name') ,type:'text'},
	{ key:'amount',name: i18n.t('OPPTYSFIELDLIST.amount') ,type:'num'},
	{ key:'expected_close_date',name: i18n.t('OPPTYSFIELDLIST.expected_close_date') ,type:'date'},
	{ key:'closed_date',name: i18n.t('OPPTYSFIELDLIST.closed_date') ,type:'date'},
	{ key:'closed_reason',name: i18n.t('OPPTYSFIELDLIST.closed_reason') ,type:'opt'},
	{ key:'status',name: i18n.t('OPPTYSFIELDLIST.status') ,type:'opt'},
	{ key:'forecast_category',name: i18n.t('OPPTYSFIELDLIST.forecast_category') ,type:'opt'},
	{ key:'customer_needs',name: i18n.t('OPPTYSFIELDLIST.customer_needs') ,type:'text'},
	{ key:'customer_budget',name: i18n.t('OPPTYSFIELDLIST.customer_budget') ,type:'num'},
	{ key:'budget_confirmed',name: i18n.t('OPPTYSFIELDLIST.budget_confirmed') ,type:'bol'},
	{ key:'primary_campaign_id',name: i18n.t('OPPTYSFIELDLIST.primary_campaign_id') ,type:'fk_cam'},
	{ key:'stage_type',name: i18n.t('OPPTYSFIELDLIST.stage_type') ,type:'opt'},
	{ key:'oppty_contact',name: i18n.t('OPPTYSFIELDLIST.oppty_contact'),type:'fk_contact'},
	{ key:'oppty_org',name: i18n.t('OPPTYSFIELDLIST.oppty_contact') ,type:'fk_org'},
	{ key:'current_stage',name: i18n.t('OPPTYSFIELDLIST.current_stage') ,type:'opt'},
	{ key:'primary_contact',name: i18n.t('OPPTYSFIELDLIST.primary_contact') ,type:'fk_contact'},
	{ key:'description',name: i18n.t('OPPTYSFIELDLIST.description') ,type:'text'},
	{ key:'customer_needs',name: i18n.t('OPPTYSFIELDLIST.customer_needs') ,type:'text'},
];
export default opptysFieldList;