import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang


const stageTypeList =  [
    { key: "DEAL", name: i18n.t('DEAL.TITLE') },
    { key: "CASE", name: i18n.t('CASE.TITLE') },   
];

export default stageTypeList;