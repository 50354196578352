import i18n from '/common/plugins/vue-i18n.js';
import CustomOpptyClosedReasonDataService from "@/services/CustomOpptyClosedReasonDataService";

// decide language to show
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW";				
}
i18n.locale = lang;

async function get_oppty_closed_reason(){
    // get custom oppty closed reason and combine it
    let closeReasonList =  [
        { key: 0, name: i18n.t('CLOSEREASONLIST.0'), selected: true },
        { key: 1, name: i18n.t('CLOSEREASONLIST.1'), selected: true },
        // { key: 2, name: i18n.t('CLOSEREASONLIST.2') },
        // { key: 3, name: i18n.t('CLOSEREASONLIST.3') },
    ];
    let count = 2;
    await CustomOpptyClosedReasonDataService.opptyclosedreasonlist().then((response)=>{
        let oppty_closed_reason_data = response.data;
        for(let index in oppty_closed_reason_data){
            let temp_data = {};
            temp_data['key'] = count;
            if (count == 2)
                temp_data['name'] = (oppty_closed_reason_data[index].reason == 'CLOSEREASONLIST.2') ? i18n.t('CLOSEREASONLIST.2'):oppty_closed_reason_data[index].reason;
            else if (count == 3)
                temp_data['name'] = (oppty_closed_reason_data[index].reason == 'CLOSEREASONLIST.3') ? i18n.t('CLOSEREASONLIST.3'):oppty_closed_reason_data[index].reason;
            else
                temp_data['name'] = oppty_closed_reason_data[index].reason;
            temp_data['selected'] = oppty_closed_reason_data[index].selected;
            closeReasonList.push(temp_data);
            count+=1;
        }
    });
    return closeReasonList;
}

export default get_oppty_closed_reason;