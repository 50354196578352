import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const permissionTypeList =  [
    { key: "DEAL", name: i18n.t('DEAL.TITLE') },
    { key: "CASE", name: i18n.t('CASE.TITLE') },
    { key: "CONTACT", name: i18n.t('CONTACT.TITLE') },
    { key: "ACCOUNT", name: i18n.t('COMPANY.TITLE') },
    { key: "SEGMENT", name: i18n.t('SEGMENT.TITLE') },
    { key: "CAMPAIGN", name: i18n.t('CAMPAIGN.TITLE') },
    { key: "ACTIVITIES", name: i18n.t('ACTIVITY.TITLE') },
];

export default permissionTypeList;

