import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const caseStatusList =  [    
    
    { key: "OPEN", name: i18n.t('CASESTATUSLIST.OPEN') },
    { key: "PENDING", name: i18n.t('CASESTATUSLIST.PENDING') },
    { key: "RESOLVED", name: i18n.t('CASESTATUSLIST.RESOLVED') },
    { key: "CLOSED", name: i18n.t('CASESTATUSLIST.CLOSED')},
    { key: "WAIT_CUSTOMER", name: i18n.t('CASESTATUSLIST.WAIT_CUSTOMER') },
    { key: "WAIT_3RD_PARTY", name: i18n.t('CASESTATUSLIST.WAIT_3RD_PARTY') },
    
];

export default caseStatusList;