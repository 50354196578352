import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const casesFieldList = [
    { key:'created_on', name: i18n.t('CASESFIELDLIST.created_on'),type:'date'},
    { key:'created_by', name: i18n.t('CASESFIELDLIST.created_by'),type:'user'},
    { key:'updated_on', name: i18n.t('CASESFIELDLIST.updated_on'),type:'date'},
    { key:'updated_by',name: i18n.t('CASESFIELDLIST.updated_by'),type:'user'},
    { key:'owner',name: i18n.t('CASESFIELDLIST.owner'),type:'user'},
    { key:'subject',name: i18n.t('CASESFIELDLIST.subject'),type:'text'},
    { key:'case_number',name: i18n.t('CASESFIELDLIST.case_number'),type:'text'},
    { key:'due_date',name: i18n.t('CASESFIELDLIST.due_date'),type:'date'},
    { key:'incoming_fullname',name: i18n.t('CASESFIELDLIST.incoming_fullname'),type:'text'},
    { key:'incoming_phone',name: i18n.t('CASESFIELDLIST.incoming_phone'),type:'text'},
    { key:'incoming_email',name: i18n.t('CASESFIELDLIST.incoming_email'),type:'text'},
    { key:'source',name: i18n.t('CASESFIELDLIST.source'),type:'opt'},
    { key:'status',name: i18n.t('CASESFIELDLIST.status'),type:'opt'},
    { key:'closed_date',name: i18n.t('CASESFIELDLIST.closed_date'),type:'date'},
    { key:'closed_reason',name: i18n.t('CASESFIELDLIST.closed_reason'),type:'opt'},
    { key:'priority',name: i18n.t('CASESFIELDLIST.priority'),type:'opt'},
    { key:'primary_campaign_id',name: i18n.t('CASESFIELDLIST.primary_campaign_id'),type:'fk_cam'},
    { key:'primary_contact',name: i18n.t('CASESFIELDLIST.primary_contact'),type:'fk_contact'},
    { key:'case_org',name: i18n.t('CASESFIELDLIST.case_org'),type:'fk_org'},
    { key:'group',name: i18n.t('CASESFIELDLIST.group'),type:'opt'},
    { key:'type',name: i18n.t('CASESFIELDLIST.type'),type:'opt'},
    { key:'stage_type',name: i18n.t('CASESFIELDLIST.stage_type'),type:'opt'},
    { key:'current_stage',name: i18n.t('CASESFIELDLIST.current_stage'),type:'opt'},
    { key:'description',name: i18n.t('CASESFIELDLIST.description'),type:'text'},
    { key:'resolution',name: i18n.t('CASESFIELDLIST.resolution'),type:'text'},
    { key:'product',name: i18n.t('CASESFIELDLIST.product'),type:'fk_product'},
    { key:'description',name: i18n.t('CASESFIELDLIST.description'),type:'text'},
	{ key:'parent_case_id',name: i18n.t('CASESFIELDLIST.parent_case_id'),type:'fk_parentCase'},
];
export default casesFieldList;