import i18n from '/common/plugins/vue-i18n.js'  

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const kaStatusList =  [
    { key: "PUBLISHED", name: i18n.t('KASTATUSLIST.PUBLISHED') },
    { key: "WAIT_PUBLISH", name: i18n.t('KASTATUSLIST.WAIT_PUBLISH') },
    { key: "IN_REVIEW", name: i18n.t('KASTATUSLIST.IN_REVIEW') },
    { key: "AUTHORING", name: i18n.t('KASTATUSLIST.AUTHORING') },
];

export default kaStatusList;