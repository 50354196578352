import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const caseSourceList =  [
    { key: "EMAIL", name: i18n.t('CASESOURCELIST.EMAIL') },
    { key: "WEB_FORM", name: i18n.t('CASESOURCELIST.WEB_FORM') },
    { key: "WEB_CHAT", name: i18n.t('CASESOURCELIST.WEB_CHAT') },
    { key: "PHONE", name: i18n.t('CASESOURCELIST.PHONE') },
    { key: "LINE", name: 'Line' },
    { key: "WHATSAPP", name: 'WhatsApp' },
    { key: "STORE", name: i18n.t('CASESOURCELIST.STORE') },
    { key: "OUTBOUND", name: i18n.t('CASESOURCELIST.OUTBOUND') },
    { key: "OTHERS", name: i18n.t('CASESOURCELIST.OTHERS') },
];

export default caseSourceList;