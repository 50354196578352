import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const bulkEditList =  [
    { key: 0, name: i18n.t('BULK_EDIT_LIST.0') },
    { key: 1, name: i18n.t('BULK_EDIT_LIST.1') },
    { key: 2, name: i18n.t('BULK_EDIT_LIST.2') },
];

export default bulkEditList;