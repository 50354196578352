import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const closeCaseReasonList =  [
    { key: "RESOLVED", name: i18n.t('CLOSECASEREASONLIST.RESOLVED') },
    { key: "ALTERNATIVE", name: i18n.t('CLOSECASEREASONLIST.ALTERNATIVE') },
    { key: "NO_SOLUTION", name: i18n.t('CLOSECASEREASONLIST.NO_SOLUTION') },
    { key: "NO_RESPONSE", name: i18n.t('CLOSECASEREASONLIST.NO_RESPONSE') },
    { key: "NOT_AN_ISSUE", name: i18n.t('CLOSECASEREASONLIST.NOT_AN_ISSUE') },
];

export default closeCaseReasonList;