import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const campaignTypeList =  [
    { key: "ADVERTISEMENT", name: i18n.t('CAMPAIGNTYPELIST.ADVERTISEMENT') },
    { key: "DIRECT_MARKETING", name: i18n.t('CAMPAIGNTYPELIST.DIRECT_MARKETING') },
    { key: "MARKETING_EVENT", name: i18n.t('CAMPAIGNTYPELIST.MARKETING_EVENT') },
    { key: "JOINT_MARKETING", name: i18n.t('CAMPAIGNTYPELIST.JOINT_MARKETING') },
    { key: "OTHERS", name: i18n.t('CAMPAIGNTYPELIST.OTHERS') },    
];

export default campaignTypeList;