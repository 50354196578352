import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const op_date_List = [
  { key:'on', name: i18n.t('OP_DATE.ON')},
	{ key:'on_or_after', name: i18n.t('OP_DATE.ON_OR_AFTER')},
	{ key:'on_or_before', name: i18n.t('OP_DATE.ON_OR_BEFORE')},
	{ key:'today',name: i18n.t('OP_DATE.TODAY')},
	{ key:'yesterday',name: i18n.t('OP_DATE.YESTERDAY')},
	{ key:'tomorrow', name: i18n.t('OP_DATE.TOMORROW')},
	{ key:'next_7_days', name: i18n.t('OP_DATE.NEXT_7_DAYS')},
	{ key:'last_7_days', name: i18n.t('OP_DATE.LAST_7_DAYS')},
	{ key:'this_week',name: i18n.t('OP_DATE.THIS_WEEK')},
	{ key:'this_month',name: i18n.t('OP_DATE.THIS_MONTH')},
	{ key:'this_quarter', name: i18n.t('OP_DATE.THIS_QUARTER')},
	{ key:'this_year', name: i18n.t('OP_DATE.THIS_YEAR')},
	{ key:'next_week', name: i18n.t('OP_DATE.NEXT_WEEK')},
	{ key:'next_month',name: i18n.t('OP_DATE.NEXT_MONTH')},
	{ key:'next_quarter',name: i18n.t('OP_DATE.NEXT_QUARTER')},
	{ key:'next_year', name: i18n.t('OP_DATE.NEXT_YEAR')},
	{ key:'last_week', name: i18n.t('OP_DATE.LAST_WEEK')},
	{ key:'last_month', name: i18n.t('OP_DATE.LAST_MONTH')},
	{ key:'last_quarter',name: i18n.t('OP_DATE.LAST_QUARTER')},
	{ key:'last_year',name: i18n.t('OP_DATE.LAST_YEAR')},

];

export default op_date_List;