import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const numberTypeList =  [
    { key: "3", name: i18n.t('NUMBERTYPELIST.3') },
    { key: "4", name: i18n.t('NUMBERTYPELIST.4') },
    { key: "5", name: i18n.t('NUMBERTYPELIST.5')},
    { key: "6", name: i18n.t('NUMBERTYPELIST.6') },  
    { key: "7", name: i18n.t('NUMBERTYPELIST.7')},
    { key: "8", name: i18n.t('NUMBERTYPELIST.8') },  
    { key: "9", name: i18n.t('NUMBERTYPELIST.9') },
    { key: "10", name: i18n.t('NUMBERTYPELIST.10') },  
    { key: "11", name: i18n.t('NUMBERTYPELIST.11') },
    { key: "12", name: i18n.t('NUMBERTYPELIST.12') },  
    { key: "13", name: i18n.t('NUMBERTYPELIST.13') },
    { key: "14", name: i18n.t('NUMBERTYPELIST.14') },  
    { key: "15", name: i18n.t('NUMBERTYPELIST.15') },    
];

export default numberTypeList;