import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const op_num_List = [
    { key:'equal', name: i18n.t('OP_NUM.EQUAL')},
    { key:'equal_or_less_than', name: i18n.t('OP_NUM.EQUAL_LESS')},
    { key:'equal_or_larger_than', name: i18n.t('OP_NUM.EQUAL_LARGER')},
    { key:'less_than',name: i18n.t('OP_NUM.LESS')},
    { key:'larger_than',name: i18n.t('OP_NUM.LARGER')},

];

export default op_num_List;